<template>
  <div id="Top" class="layout">
    <div class="arrow">
      <button :class="history.idx != 0 && history.list.length > 1 ? 'active' : ''" @click="clickBefore"><f-icon icon="angle-left"/></button>
      <button :class="history.list.length-1 > history.idx ? 'active' : ''" @click="clickAfter"><f-icon icon="angle-right" /></button>
    </div>
    <div class="search">
      <input type="text" placeholder="검색" v-model="txtSearch" @keydown.enter="handleKeyup"/>
      <f-icon icon="search" @click="search"/>
    </div>
    <div class="action">
      <f-icon icon="bell" type="far" @click="openNoti" :style="{ color: isOpen.noti ? color.active : color.unactive }"/>
      <f-icon icon="comment" type="far" @click="openMsg" :style="{ color: isOpen.msg ? color.active : color.unactive }"/>
      <f-icon icon="user" type="far" @click="isOpen.myMenu = !isOpen.myMenu" :style="{ color: isOpen.myMenu ? color.active : color.unactive }"/>
    </div>
    <div class="myMenu" v-if="isOpen.myMenu">
      <div class="closePopup" @click="isOpen.myMenu = false"></div>
      <ul>
        <li class="info"><span>{{info.my.user_name}}</span> 님</li>
        <li class="menu" @click="openMyInfo">내 정보</li>
        <li class="menu" @click="logout">로그아웃</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import { EventBus } from "@/utils/EventBus.js";
import APIUtils from "@/utils/Network/APIUtils";
export default {
  data() {
    return {
      txtSearch : "",
      history:{
        list: null,
        idx: null
      },
      isOpen: {
        noti: false,
        msg: false,
        myMenu: false,
      },
      color: {
        active: "#5A86DD",
        unactive: "#888",
      },
    };
  },
  computed: {
    ...mapGetters({ info: "getSaveInfo" }),
  },
  created() {
    let objThis = this
    this.history.list = ['root']
    this.history.idx = 0
    EventBus.$on("bus:openFolder", function(folder_id, self){
      if(!self){
        objThis.history.list.splice(objThis.history.idx + 1)
        objThis.history.list.push(folder_id)
        objThis.history.idx += 1
      }
    })
  },
  methods: {
    clickBefore(){
      if(this.history.idx > 0){
        this.history.idx -= 1
        EventBus.$emit("bus:openFolder", this.history.list[this.history.idx], true)
      }
    },
    clickAfter(){
      if(this.history.idx < this.history.list.length-1){
        this.history.idx += 1
        EventBus.$emit("bus:openFolder", this.history.list[this.history.idx], true)
      }
    },
    handleKeyup(e){
      if(!e.isComposing)
        this.search()
    },
    search(){
      if(this.txtSearch.length){
        console.log(this.txtSearch)
        EventBus.$emit("bus:openSearch", this.txtSearch)
        // APIUtils.file_search({ori_name: this.txtSearch}).then(res=>{
        //   console.log(res)
        // })
        // APIUtils.folder_search({folder_name: this.txtSearch}).then(res=>{
        //   console.log(res)
        // })
      }
    },
    openNoti() {
      this.isOpen.noti = !this.isOpen.noti;
      EventBus.$emit("bus:openNoti", this.isOpen.noti);
    },
    openMsg() {
      this.isOpen.msg = !this.isOpen.msg;
      EventBus.$emit("bus:openMsg", this.isOpen.msg);
    },
    openMyInfo(){
      this.isOpen.myMenu = false
      EventBus.$emit("bus:openMy");
    },
    ...mapActions(['logout'])
  },
};
</script>

<style scoped></style>
